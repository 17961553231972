<template>
    <v-container fluid id="users_page">

        <!--Datatable Toolbar-->
        <v-toolbar flat color="white" elevation="1" class="custom_datatable_toolbar" height="56">
            <v-toolbar-title>{{$t('users.list')}}</v-toolbar-title>
        </v-toolbar>

        <!--Datatable-->
        <ResponsiveDataTable :headers="headers" :items="items" :mobileBreakpoint="960">
            <!--Buttons-->
            <template v-slot:buttons="{item}">
                <div class="actions">
                    <v-icon color="primary" :title="$t('users.settings')"
                            @click="show_user_settings(item.item)">edit
                    </v-icon>
                </div>
            </template>
        </ResponsiveDataTable>

        <Dialog :show="show_user_settings_dialog" max_width="80%" :header="$t('users.settings')"
                @close-dialog="close_user_settings" @submit-dialog="save_user_settings">
            <template slot="content">
                <v-form ref="form" v-model="valid">
                    <v-jsf v-model="model" :schema="schema" :options="options" />
                </v-form>
            </template>
        </Dialog>
    </v-container>
</template>

<script>
    import ResponsiveDataTable from '../../components/ResponsiveDataTable.vue';
    import Dialog from '../../components/Dialog.vue';
    import VJsf from '@koumoul/vjsf/lib/VJsf.js';
    import '@koumoul/vjsf/lib/VJsf.css';
    import '@koumoul/vjsf/lib/deps/third-party.js';

    export default {
        components: {
            ResponsiveDataTable, Dialog, VJsf
        },
        data: self => ({
            // datatable
            headers: [],
            items: [],
            loading: false,
            user_id: null,
            options: {
                page: null,
                itemsPerPage: null,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            },
            show_user_settings_dialog: false,
            valid: false,
            model: {},
            schema: {}
        }),
        created() {
            this.headers = this.getHeaders();
        },
        async mounted() {
            let response = await this.$store.dispatch('apiRequest', {
                method: 'get', url: 'user_list'
            });


            // successful response
            if (!response.response) {
                this.items = response.data;
            }
        },
        methods: {
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('general.id')}`, value: 'id', sortable: true},
                    {text: `${this.$t('general.name')}`, value: 'name', sortable: true},
                    {text: `${this.$t('general.lastname')}`, value: 'lastname', sortable: true},
                    {text: `${this.$t('datatable.actions')}`, value: 'actions', align: 'right', sortable: false}
                ]
            },
            show_user_settings: async function (item) {
                let response = await this.$store.dispatch('apiRequest', {
                    method: 'get',
                    url: 'user_actions_json',
                    params: {
                        user_id: item.id
                    }
                });

                this.user_id = item.id;
                this.schema = JSON.parse(response);
                this.show_user_settings_dialog = true;
            },
            close_user_settings(){
                this.show_user_settings_dialog = false;
            },
            async save_user_settings(){

                let user_settings_json = {};
                this.schema.allOf.forEach(function(item){

                    user_settings_json[item.lower_case_title] = {};
                    user_settings_json[item.lower_case_title].friendly_name = item.title;
                    user_settings_json[item.lower_case_title].active = item.active;

                    Object.entries(item.properties).forEach(function(property){
                        user_settings_json[item.lower_case_title][property[0]] = {};
                        user_settings_json[item.lower_case_title][property[0]].active = 'true';
                        user_settings_json[item.lower_case_title][property[0]].count= 0;
                        user_settings_json[item.lower_case_title][property[0]].friendly_name = property[1].title;
                        user_settings_json[item.lower_case_title][property[0]].total_amount = 0;
                        user_settings_json[item.lower_case_title][property[0]].cost_per_count = document.getElementById(property[0]).value;
                        user_settings_json[item.lower_case_title][property[0]].type = 'number';
                    });
                });

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'post',
                    url: 'save_user_settings',
                    data: {user_id: this.user_id, settings: JSON.stringify(user_settings_json)}
                });
                this.show_user_settings_dialog = false;
            }
        }
    }
</script>