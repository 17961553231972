<template>
    <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="totalItems"
                  :footer-props="footer_props" :loading="loading" :no-data-text="$t('datatable.no_available_data')"
                  :mobile-breakpoint="mobileBreakpoint" class="elevation-1 custom_datatable">

        <!--Loading-->
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>

        <!--Body-->
        <template v-slot:body="{ items }">
            <tbody>
                <tr v-if="items.length === 0" class="v-data-table__empty-wrapper">
                    <td colspan="6">{{$t('datatable.no_available_data')}}</td>
                </tr>
                <tr v-else v-for="(item, item_index) in items" :key="item.name"
                    :class="['status_' + item.order_status_id]">
                    <td v-if="screen_width >= mobileBreakpoint" v-for="(header, index) in headers" :key="`dt_${index}`"
                        :class="{'text-right':header.align === 'right'}">
                        <span v-if="header.value === 'status'">
                           <slot name="status" :item="item"></slot>
                        </span>
                        <span v-if="header.value !== 'actions'">{{item[header.value]}}</span>
                        <span v-else>
                            <slot name="buttons" :item="{item, item_index}"></slot>
                        </span>
                    </td>
                    <td v-if="screen_width < mobileBreakpoint" v-for="(header, index) in headers" :key="`dt_${index}`"
                        class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">{{header.text}}</div>
                        <div class="v-data-table__mobile-row__cell">
                            <span v-if="header.value === 'status'">
                                <slot name="status" :item="item"></slot>
                            </span>
                            <span v-if="header.value !== 'actions'">{{item[header.value]}}</span>
                            <span v-else>
                            <slot name="buttons" :item="{item, item_index}"></slot>
                        </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </template>

    </v-data-table>
</template>

<script>
    export default {
        props: {
            headers: {type: Array},
            items: {type: Array},
            totalItems: {type: Number},
            loading: {type: Boolean, default: false},
            page: {type: Number, default: 1},
            itemsPerPageOptions: {type: Array, default: () => [10, 20, 50, 100, 200]},
            isDescending: {type: Boolean, default: false},
            sortByColumn: {type: String, default: '0'},
            mobileBreakpoint: {type: Number, default: 600},
            itemsPerPage: {type: Number, default: 10},
        },
        data: self => ({
            options: {
                page: self.page,
                itemsPerPage: self.itemsPerPage,
                sortBy: self.sortByColumn ? [self.headers[self.sortByColumn].value] : [],
                sortDesc: [self.isDescending],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            },
            footer_props: {
                'items-per-page-options': self.itemsPerPageOptions,
                'items-per-page-text': `${self.$t('datatable.items_per_page')}`
            },
            selected: []
        }),
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            screen_width() {
                return this.$vuetify.breakpoint.width;
            }
        },
        watch: {
            options: {
                handler(val) {
                    this.$emit('options-changed', val);
                },
                deep: true
            },
            // change text when locale changed
            locale(val) {
                this.footer_props['items-per-page-text'] = this.$t('datatable.items_per_page');
            }
        }
    }
</script>